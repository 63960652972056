var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "550px", "max-height": "551px", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "px-0 pb-0 pt-0" },
        [
          _c(
            "v-card-title",
            { staticClass: "px-4" },
            [
              _c(
                "span",
                {
                  staticClass: "text-h5",
                  style:
                    "color: #182026;font-weight:500;font-size:20px !important",
                },
                [_vm._v(_vm._s(_vm.titulo))]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                [_c("v-icon", { staticClass: "ma-0" }, [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "ma-0 pa-4" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "pa-0 ma-0",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "idCodigo",
                              label: "Código *",
                              required: "",
                              maxlength: "4",
                              minlength: "4",
                              hint: _vm.codigoHint,
                              dense: "",
                              outlined: "",
                              disabled: "",
                              "hide-details": "auto",
                            },
                            model: {
                              value: _vm.incidenceForEdit.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.incidenceForEdit, "code", $$v)
                              },
                              expression: "incidenceForEdit.code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-4 pb-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "idNome",
                              rules: [_vm.rules.requiredName],
                              label: "Nome *",
                              "hide-details": "auto",
                              dense: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.incidenceForEdit.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.incidenceForEdit, "name", $$v)
                              },
                              expression: "incidenceForEdit.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-4 pb-4", attrs: { cols: "12" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              rows: "4",
                              cols: "40",
                              "hide-details": "auto",
                              maxlength: "300",
                              counter: 300,
                              label: "Justificativa *",
                              rules: [_vm.rules.requiredJust],
                              dense: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.incidenceForEdit.notes,
                              callback: function ($$v) {
                                _vm.$set(_vm.incidenceForEdit, "notes", $$v)
                              },
                              expression: "incidenceForEdit.notes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "text-left pt-8",
                  staticStyle: {
                    "font-size": "14px",
                    color: "#575767",
                    "font-weight": "400",
                  },
                },
                [_vm._v(" * Campos de preenchimento obrigatório ")]
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "btn-ag-tertiary",
                  attrs: { text: "", id: "btnCancel" },
                  on: { click: _vm.close },
                },
                [_vm._v(" Cancelar")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn-ag-primary",
                  attrs: { id: "btnConfirmOredit", disabled: _vm.isEnableSave },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.labelBotao) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }